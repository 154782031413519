<template>
  <div class="my-exam-detail">
    <Exam @error="handleError" />
  </div>
</template>

<script>
import { Exam } from 'exam-service-web-lib';

export default {
  name: 'ExamPage',
  components: {
    Exam,
  },
  methods: {
    handleError(err) {
      switch (err.code) {
        case 404:
          this.$router.push('/not-found');
          break;

        default:
      }
    },
  },
};
</script>
